import Apiservice from '@/common/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;

export default class EcommerceShippingMethodPriceService {
      #api;
      constructor() {
            this.#api = API_URL + 'user/website';
      }

      paginate(domainname, methodId) {
            let url = `${this.#api}/${domainname}/shipping-method/${methodId}/price`;
            return apiService.get(url)
      }

      create(domainname, methodId, data) {
            let url = `${this.#api}/${domainname}/shipping-method/${methodId}/price`;
            return apiService.post(url, data)
      }

      show(domainname, methodId, id) {
            let url = `${this.#api}/${domainname}/shipping-method/${methodId}/price/${id}`;
            return apiService.get(url)
      }

      update(domainname, methodId, id, data) {
            let url = `${this.#api}/${domainname}/shipping-method/${methodId}/price/${id}`;
            return apiService.post(url, data)
      }

      delete(domainname, methodId, id) {
            let url = `${this.#api}/${domainname}/shipping-method/${methodId}/price/${id}`;
            return apiService.delete(url)
      }
}
