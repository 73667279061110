<template>
  <section id="flat_rate_method">
    <v-app>
      <v-row>
        <h1>Custom Rate</h1>
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="8" class="ml-5">
                  <!-- <v-col cols="12">
                    <h4 class="card-title">Carrier Name</h4>

                    <v-text-field v-model="method.carrier_name" outlined dense label="Carrier Name"></v-text-field>
                    <span class="text-danger" v-if="$v.method.carrier_name.$error">
                      <strong>Title is required</strong>
                    </span>
                  </v-col>-->
                  <v-col cols="12">
                    <h4 class="card-title">Shipping name at checkout</h4>
                    <p>Enter the name under which customers see this shipping method at checkout.</p>
                    <v-text-field v-model="method.title" outlined dense label="Standard shipping"></v-text-field>
                    <span class="text-danger" v-if="$v.method.title.$error">
                      <strong>{{validationMessage.title}}</strong>
                    </span>
                  </v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="6">
                        <p class="text-muted">Subtotal Range</p>
                      </v-col>
                      <v-col cols="6">
                        <p class="text-muted">Subtotal Range</p>
                      </v-col>
                    </v-row>
                    <hr />
                  </v-col>
                  <v-col cols="12">
                    <v-row v-for="(p, index) of method.prices" :key="index">
                      <v-col cols="6">
                        <v-row>
                          <v-col cols="5">
                            <v-text-field
                              type="number"
                              v-if="method.range_type =='weight'"
                              label="Start From"
                              outlined
                              dense
                              suffix="kg"
                              v-model="p.range_start_from"
                            ></v-text-field>
                            <v-text-field
                              type="number"
                              v-else
                              label="Start From"
                              outlined
                              dense
                              :prefix="currency_symbol"
                              v-model="p.range_start_from"
                            ></v-text-field>
                            <span
                              class="text-danger"
                              v-if="$v.method.prices.$each[index].range_start_from.$error"
                            >
                              <strong>{{validationMessage.start_from}}</strong>
                            </span>
                          </v-col>

                          <v-col cols="2">
                            <p class="mt-2">__</p>
                          </v-col>
                          <v-col cols="5">
                            <v-text-field
                              type="number"
                              v-if="method.range_type =='weight'"
                              label="End To"
                              outlined
                              dense
                              suffix="kg"
                              v-model="p.range_end_to"
                            ></v-text-field>
                            <v-text-field
                              type="number"
                              v-else
                              label="End To"
                              outlined
                              dense
                              :prefix="currency_symbol"
                              v-model="p.range_end_to"
                            ></v-text-field>
                            <span
                              class="text-danger"
                              v-if="$v.method.prices.$each[index].range_end_to.$error"
                            >
                              <strong>{{validationMessage.end_to}}</strong>
                            </span>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col cols="6">
                        <v-col cols="12" v-if="method.rate_based_on == 'range_only'">
                          <v-text-field
                            type="number"
                            v-model="p.price"
                            outlined
                            dense
                            :prefix="currency_symbol"
                            label="Price"
                          ></v-text-field>
                          <span
                            class="text-danger"
                            v-if="$v.method.prices.$each[index].price.$error"
                          >
                            <strong>{{validationMessage.price}}</strong>
                          </span>
                        </v-col>
                        <v-col cols="12" v-else>
                          <v-col cols="12">
                            <v-text-field
                              type="number"
                              outlined
                              dense
                              :prefix="currency_symbol"
                              label="Per order"
                              v-model="p.per_order_price"
                            ></v-text-field>
                            <span
                              class="text-danger"
                              v-if="$v.method.prices.$each[index].per_order_price.$error"
                            >
                              <strong>{{validationMessage.per_order_price}}</strong>
                            </span>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              type="number"
                              v-model="p.per_item_price"
                              outlined
                              dense
                              :prefix="currency_symbol"
                              label="Per item"
                            ></v-text-field>
                            <span
                              class="text-danger"
                              v-if="$v.method.prices.$each[index].per_item_price.$error"
                            >
                              <strong>{{validationMessage.per_item_price}}</strong>
                            </span>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              type="number"
                              outlined
                              dense
                              :prefix="'%'"
                              label="Percent charge"
                              v-model="p.percentage_charge"
                            ></v-text-field>
                            <span
                              class="text-danger"
                              v-if="$v.method.prices.$each[index].percentage_charge.$error"
                            >
                              <strong>{{validationMessage.percentage_charge}}</strong>
                            </span>
                          </v-col>

                          <v-col cols="12">
                            <v-text-field
                              type="number"
                              outlined
                              dense
                              :prefix="currency_symbol"
                              v-model="p.price_per_kg"
                              label="Per kg"
                            ></v-text-field>
                            <span
                              class="text-danger"
                              v-if="$v.method.prices.$each[index].price_per_kg.$error"
                            >
                              <strong>{{validationMessage.price_per_kg}}</strong>
                            </span>
                          </v-col>
                        </v-col>
                        <v-btn @click="removePrice(p.id, index)" outlined dense class="pull-right"><i class="fa fa-trash"></i></v-btn>
                      </v-col>
                    </v-row>
                    <v-btn outlined dense @click="appendShippingRate">Add Row</v-btn>
                    <hr />
                  </v-col>

                  <v-col cols="12">
                    <hr />
                    <v-row>
                      <v-col cols="6">
                        <v-select
                          outlined
                          dense
                          v-model="method.range_type"
                          :items="table_based_on"
                          label="Table based on"
                          item-text="title"
                          item-value="value"
                        ></v-select>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          outlined
                          dense
                          v-model="method.rate_based_on"
                          :items="rate_based_on"
                          label="Rate Based on"
                          item-text="title"
                          item-value="value"
                          @change="onRangeBasedChange()"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <h4 class="card-title">Description for customers</h4>
                    <p>What should customers know when choosing this shipping method for their order? Specify the time it usually takes for orders to arrive, the way in which the shipment will be handed over to the buyer, or anything else you feel is important for customers to know about shipping option. This information will be displayed at checkout.</p>
                    <v-textarea outlined dense v-model="method.description"></v-textarea>
                  </v-col>

                  <v-col cols="12">
                    <h4 class="card-title">Minimum order subtotal</h4>
                    <p>Specify the order subtotal before discounts. This delivery method won’t be available at checkout for orders below that amount.</p>
                    <v-text-field
                      outlined
                      v-model="method.minimum_order_subtotal"
                      :prefix="currency_symbol"
                      dense
                      label="0.00"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <h4 class="card-title">Shipping region</h4>
                    <p>Specify the destination that you ship to with this method. If shipping rates should differ depending on the customer's address, create a new shipping method and assign it to the corresponding destination zone.</p>
                    <v-radio-group v-model="method.shipping_region">
                      <v-radio
                        class="radio-item"
                        v-for="(option, n) in options"
                        :key="n"
                        :label="option.title"
                        :value="option.value"
                        @change="onChangeRadio(option.value)"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-row>
                    <v-col cols="12" v-if="advance_setting">
                      <p>Set delivery zone by country, ZIP/postal code range, or state if you deliver to specific regions.</p>
                      <v-row>
                        <v-col cols="8">
                          <v-select
                            outlined
                            v-model="method.ecommerce_shipping_region_id"
                            dense
                            :items="zones"
                            item-text="title"
                            item-value="id"
                          ></v-select>
                          <span
                            class="text-danger"
                            v-if="$v.method.ecommerce_shipping_region_id.$error"
                          >
                            <strong>{{validationMessage.ecommerce_shipping_region_id}}</strong>
                          </span>
                        </v-col>
                        <v-col cols="4">
                          <router-link :to="{name: 'shipping-zone'}">Manage Zone</router-link>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-col cols="12" class="text-right">
                    <v-btn outlined dense @click="createOrUpdate" :loading="loading">Save</v-btn>
                  </v-col>
                </v-col>
                <v-col cols="4">
                  <!-- <pre>{{this.method}}</pre> -->
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-app>
  </section>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import EcommerceShippingMethodService from "@/services/store/shipping/method/EcommerceShippingMethodService";
import EcommerceShippingMethodPriceService from "@/services/store/shipping/method/EcommerceShippingMethodPriceService";
import EcommerceShippingRegionService from "@/services/store/shipping/EcommerceShippingRegionService";

const methodService = new EcommerceShippingMethodService();
const regionService = new EcommerceShippingRegionService();
const priceService = new EcommerceShippingMethodPriceService();

export default {
  name:'custom-rate',
  validations() {
    return {
      method: this.rules
    };
  },
  data() {
    return {
      methodId: null,
      loading: false,
      optionValue: null,
      advance_setting: false,
      options: [
        { title: "All Destination", value: "all" },
        { title: "Advance Setting", value: "customized" }
      ],
      table_based_on: [
        { title: "Subtotal(after all discount)", value: "money" },
        { title: "Weight", value: "weight" }
      ],
      rate_based_on: [
        { title: "Range Only", value: "range_only" },
        { title: "Range, Subtotal, Weight", value: "range_subtotal_weight" }
      ],
      zones: [],
      shippingMethodType: null,
      domainname: null,
      loading: false,
      method: {
        type: null,
        title: null,
        is_active: 1,
        is_range: 1,
        shipping_region: "all",
        minimum_order_subtotal: null,
        ecommerce_shipping_region_id: null,
        range_type: "money",
        rate_based_on: "range_only",
        description: null,
        carrier_name: null,
        prices: [
          {
            range_start_from: null,
            range_end_to: null,
            percentage_charge: null,
            per_order_price: null,
            per_item_price: null,
            price_per_kg: null,
            price: null
          }
        ]
      },
      validationMessage: {
        start_from: "Start from cannot be blank",
        end_to: "End to  cannot be blank",
        price: "Price cannot be blank",
        per_order_price: "Pre order price cannot be blank",
        per_item_price: "Per item price cannot be blank",
        percentage_charge: "Percentage charge cannot be blank",
        price_per_kg: "Price per kg cannot be blank",
        title: "Title is required",
        ecommerce_shipping_region_id: "Please select region"
      }
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    currency_symbol() {
      return this.currentUser.store_config.currency_symbol;
    },
    rules() {
      let method = {
        title: { required }
      };
      if (this.method.shipping_region === "customized")
        method.ecommerce_shipping_region_id = { required };
      if (this.method.rate_based_on == "range_only") {
        method.prices = {
          required,
          $each: {
            range_start_from: { required },
            range_end_to: { required },
            price: { required }
          }
        };
      }
      if (this.method.rate_based_on == "range_subtotal_weight") {
        method.prices = {
          required,
          $each: {
            range_start_from: { required },
            range_end_to: { required },
            percentage_charge: { required },
            per_order_price: { required },
            per_item_price: { required },
            price_per_kg: { required }
          }
        };
      }
      return method;
    }
  },
  mounted() {
    this.domainname = this.$route.params.domainname;
    this.shippingMethodType = "custom";
    this.method.type = this.shippingMethodType;
    this.setCarrierName();
    if (
      this.$route.params.id != null &&
      this.$route.params.id != undefined &&
      this.$route.params.id != ""
    ) {
      this.methodId = this.$route.params.id;
      this.getMethod(this.methodId);
    }
    this.getZones();
  },
  methods: {
     setCarrierName(){
      this.method.carrier_name=  (this.$route.params.carrier_name)?this.$route.params.carrier_name:null;
    },
    validationMessage: {
      start_from: "Start from cannot be blank",
      end_to: "End to  cannot be blank",
      price: "Price cannot be blank",
      per_order_price: "Pre order price cannot be blank",
      per_item_price: "Per item price cannot be blank",
      percentage_charge: "Percentage charge cannot be blank",
      price_per_kg: "Price per kg cannot be blank",
      title: "Title"
    },
    getMethod(id) {
      methodService.show(this.domainname, id).then(response => {
        this.method = response.data.method;
        if (this.method.shipping_region === "customized") {
          this.advance_setting = true;
        }
        this.edit = true;
      });
    },
    getZones() {
      regionService.getByWebsite(this.domainname).then(response => {
        this.zones = response.data.regions;
      });
    },
    onChangeRadio(value) {
      if (value === "customized") {
        this.advance_setting = true;
      } else {
        this.advance_setting = false;
        this.method.ecommerce_shipping_region_id = null;
      }
      // console.log(this.rules);
    },

    createOrUpdate() {
      this.loading = true;
      this.$v.$touch();
      if (this.$v.method.$error) {
        setTimeout(() => {
          this.$v.method.$reset();
          this.loading = false;
        }, 3000);
      } else {
        if (this.edit) {
          this.update();
        } else {
          this.save();
        }
      }
    },
    save() {
      methodService
        .create(this.domainname, this.method)
        .then(response => {
          this.reset();
        })
        .catch(error => {
          // console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update() {
      methodService
        .update(this.domainname, this.method.id, this.method)
        .then(response => {
          this.reset();
        })
        .catch(error => {
          // console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    reset() {
      this.$v.method.$reset();
      this.$snotify.success("Updated Successfully");
      this.$router.push({ name: "shipping" });
    },
    appendShippingRate() {
      this.method.prices.push({
        range_start_from: null,
        range_end_to: null,
        percentage_charge: null,
        per_order_price: null,
        per_item_price: null,
        price_per_kg: null,
        price: null
      });
    },
    removePrice(id, index) {
      if (id != null && id != undefined && id != "") {
          this.$confirm(
              {
                  message: `Are you sure?`,
                  button: {
                      no: 'No',
                      yes: 'Yes'
                  },
                  /**
                   * Callback Function
                   * @param {Boolean} confirm
                   */
                  callback: confirm => {
                      if (confirm) {
                          priceService
                              .delete(this.domainname, this.methodId, id)
                              .then(response => {
                                  this.$snotify.success("Updated Successfully");
                                  this.method.prices.splice(index, 1);
                              });
                      }
                  }
              }
          )

      } else {
        this.method.prices.splice(index, 1);
      }
    },
    onRangeBasedChange() {
      for (let i in this.method.prices) {
        this.method.prices[0] = {
          range_start_from: null,
          range_end_to: null,
          percentage_charge: null,
          per_order_price: null,
          per_item_price: null,
          price_per_kg: null,
          price: null
        };
      }
      // console.log(this.rules);
    }
  }
};
</script>
<style scoped>
#flat_rate_method .card-title {
  font-size: 25px !important;
  font-weight: 600 !important;
}
#flat_rate_method p {
  font-size: 14px !important;
  font-weight: 500 !important;
}

#flat_rate_method .radio-item {
  font-size: 16px !important;
  font-weight: 500 !important;
}
</style>
